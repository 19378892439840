<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">車両一覧</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div v-if="myVehicle">
              <a
                class="card card--withShadow"
                :class="[$style.paymentTransaction, $style.income, $style.cardWithShadow]"
                @click="showModalgettindOff"
              >
                <div :class="$style.icon">
                  <i :class="'lnr lnr-arrow-left'"></i>
                </div>
                <div>
                  <span :class="$style.amount">現在の乗車: {{ myVehicle.name }}</span>
                  <span :class="$style.info">({{ myVehicle.branch_name }})</span>
                </div>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div v-if="driveLoading != 4" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="driveLoading == 4">
              <div>
                <span class="mr-5">フィルター</span>
                <a-form layout="inline">
                  <a-form-item label="車両名">
                    <a-auto-complete
                      :data-source="autocompleteData"
                      style="width: 200px"
                      placeholder="車両名"
                      :filter-option="filterOption"
                      @select="searchAction"
                      @search="searchAction"
                    />
                  </a-form-item>
                  <a-form-item label="車両番号">
                    <a-auto-complete
                      :data-source="autocompleteDataNumber"
                      style="width: 200px"
                      placeholder="車両番号"
                      :filter-option="filterOption"
                      @select="searchActionNumber"
                      @search="searchActionNumber"
                    />
                  </a-form-item>
                  <a-form-item label="支店名">
                    <a-select
                      mode="multiple"
                      placeholder="支店名"
                      :value="selectedBranches"
                      style="min-width: 300px"
                      @change="handleChange"
                    >
                      <a-select-option v-for="item in filteredOptions" :key="item.name" :value="item.name">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-form>
                <hr style="margin: 20px 0 0 0">
              </div>
              <a-table
                :columns="columns"
                :data-source="filteredVehiclesData"
                rowKey="id"
              >
                <span slot="action" slot-scope="id, record">
                  <a-button type="primary" shape="round" icon="import" @click="showModalRide(id, record.name)">乗車</a-button>
                  <a-button v-if="isAdmin && record.user_name" style="margin-left: 10px;" type="default" shape="round" icon="export" @click="showModalgettindOffUser(id, record.user_name)">降車</a-button>
                </span>
              </a-table>
              <a-modal
                :title="title"
                :visible="visibleRide"
                :confirm-loading="confirmLoading"
                okText='乗車する'
                okType='primary'
                cancelText='キャンセル'
                @ok="handleOkRide"
                @cancel="handleCancelRide"
              >
                <div v-if="isAdmin">
                  <p>乗車するユーザー</p>
                  <a-select
                    show-search
                    placeholder="乗車するユーザーを選択"
                    option-filter-prop="children"
                    style="width: 100%"
                    :filter-option="filterOption"
                    :default-value="myName"
                    @change="userHandleChange"
                  >
                    <a-select-option
                      v-for="user in users"
                      :key="user.id"
                      :value="user.name"
                    >
                      {{ user.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-modal>
              <a-modal
                :title="title"
                :visible="visibleGettingOff"
                :confirm-loading="confirmLoading"
                okText='降車する'
                okType='primary'
                cancelText='キャンセル'
                @ok="handleOkGettingOff"
                @cancel="handleCancelGettingOff"
              />
              <a-modal
                :title="title"
                :visible="visibleGettingOffUser"
                :confirm-loading="confirmLoading"
                okText='降車にする'
                okType='primary'
                cancelText='キャンセル'
                @ok="handleOkGettingOffUser"
                @cancel="handleCancelGettingOffUser"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import store from '@/store'

export default {
  data() {
    return {
      driveLoading: 0,
      columns: [
        {
          title: '車両名',
          dataIndex: 'name',
          sorter: (a, b) => a.name < b.name ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '車両番号',
          dataIndex: 'number',
          sorter: (a, b) => a.number < b.number ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '支店名',
          dataIndex: 'branch_name',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.branch_name < b.branch_name ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '現在の運転者',
          dataIndex: 'user_name',
          sorter: (a, b) => a.user_name < b.user_name ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'アクション',
          dataIndex: 'id',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      myVehicle: null,
      vehiclesData: [],
      selectedBranches: [],
      users: [],
      selectUserId: { user_id: null },
      branches: [],
      searchText: '',
      searchTextNumber: '',
      targetVehicleId: null,
      targetUserName: '',
      title: '',
      confirmLoading: false,
      visibleRide: false,
      visibleGettingOff: false,
      visibleGettingOffUser: false,
    }
  },
  computed: {
    myId() {
      return store.getters.id
    },
    myName() {
      return store.getters.name
    },
    isAdmin() {
      if (store.getters.role !== null && store.getters.role <= 2) return true
      else return false
    },
    filteredOptions() {
      return this.branches.filter(o => !this.selectedBranches.includes(o))
    },
    filteredVehiclesData() {
      let filteredData = this.vehiclesData
      if (this.searchText) filteredData = filteredData.filter(v => v.name.includes(this.searchText))
      if (this.searchTextNumber) filteredData = filteredData.filter(v => v.number.includes(this.searchTextNumber))
      if (this.selectedBranches.length) filteredData = filteredData.filter(v => this.selectedBranches.includes(v.branch_name))
      return filteredData
    },
    autocompleteData() {
      return this.filteredVehiclesData.map((item) => { return item.name })
    },
    autocompleteDataNumber() {
      return this.filteredVehiclesData.map((item) => { return item.number })
    },
  },
  watch: {
    isAdmin: function(val) {
      if (val) {
        const usersResult = Vue.prototype.$api.send('get', 'users')
        usersResult.then(response => {
          this.users = response
          console.log(response)
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': ユーザーの取得に失敗しました。',
            })
          })
      }
    },
  },
  created() {
    if (store.getters.role === null || store.getters.name === null || store.getters.id === null) this.$auth.userInfo()

    const myVehicleResult = Vue.prototype.$api.send('get', 'user/vehicle')
    myVehicleResult.then(response => {
      this.myVehicle = response
      this.driveLoading++
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 現在乗車中の車両の取得に失敗しました。',
        })
        this.driveLoading++
      })

    const vehicleResult = Vue.prototype.$api.send('get', 'user/vehicles')
    vehicleResult.then(response => {
      this.vehiclesData = response.map((item) => {
        if (item.user) item.user_name = item.user.name
        else item.user_name = ''
        return item
      })
      this.driveLoading++
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 車両の取得に失敗しました。',
        })
        this.driveLoading++
      })

    const branchesResult = Vue.prototype.$api.send('get', 'user/branches')
    branchesResult.then(response => {
      this.branches = response
      this.driveLoading++
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.driveLoading++
      })

    if (this.isAdmin) {
      const usersResult = Vue.prototype.$api.send('get', 'users')
      usersResult.then(response => {
        this.users = response
        this.driveLoading++
        console.log(response)
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ユーザーの取得に失敗しました。',
          })
          this.driveLoading++
        })
    } else {
      this.driveLoading++
    }
  },
  methods: {
    handleCancelGettingOff() { this.visibleGettingOff = false },
    handleCancelGettingOffUser() { this.visibleGettingOffUser = false },
    handleCancelRide() { this.visibleRide = false },
    showModalRide(id, name) {
      console.log('show Modal: ' + id + ': ' + name)
      this.targetVehicleId = id
      this.title = name + ' に乗車しますか'
      this.visibleRide = true
    },
    showModalgettindOff() {
      this.title = '降車しますか'
      this.visibleGettingOff = true
    },
    showModalgettindOffUser(id, name) {
      this.targetVehicleId = id
      this.title = name + ' を降車させますか'
      this.targetUserName = name
      this.visibleGettingOffUser = true
    },

    handleChange(selectedBranches) { this.selectedBranches = selectedBranches },
    searchAction(text) { this.searchText = text },
    searchActionNumber(text) { this.searchTextNumber = text },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    userHandleChange(value, obj) {
      console.log(`selected ${value} ${obj.key}`)
      this.selectUserId.user_id = obj.key
    },
    userFilterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleOkRide() {
      const _this = this
      this.confirmLoading = true

      if (this.isAdmin && this.selectUserId.user_id) {
        const result = Vue.prototype.$api.send('put', 'user/vehicles/' + this.targetVehicleId + '/ride', this.selectUserId)
        result.then(response => {
          _this.$notification['success']({
            message: response.name + ' に乗車しました。',
          })
          const gettingOffVehicleIndex = this.vehiclesData.findIndex((v) => v.user_id === this.selectUserId.user_id)
          if (gettingOffVehicleIndex !== -1) {
            const targetVehicle = this.vehiclesData[gettingOffVehicleIndex]
            targetVehicle.user_id = null
            targetVehicle.user_name = ''
            this.vehiclesData.splice(gettingOffVehicleIndex, 1, targetVehicle)
          }

          const targetVehicleIndex = this.vehiclesData.findIndex((v) => v.id === response.id)
          const targetVehicle = this.vehiclesData[targetVehicleIndex]
          targetVehicle.user_id = response.user_id
          targetVehicle.user_name = response.user.name
          this.vehiclesData.splice(targetVehicleIndex, 1, targetVehicle)
          if (this.selectUserId.user_id === this.myId) {
            this.myVehicle = targetVehicle
          }
          this.confirmLoading = false
          this.visibleRide = false
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': ユーザーの乗車に失敗しました。',
            })
            this.confirmLoading = false
            this.visibleRide = false
          })
      } else {
        const result = Vue.prototype.$api.send('put', 'vehicles/' + this.targetVehicleId + '/ride')
        result.then(response => {
          _this.$notification['success']({
            message: response.name + ' に乗車しました。',
          })
          if (this.myVehicle) {
            const targetVehicleIndex = this.vehiclesData.findIndex((v) => v.user_id === this.myId)
            const targetVehicle = this.vehiclesData[targetVehicleIndex]
            targetVehicle.user_id = null
            targetVehicle.user_name = ''
            this.vehiclesData.splice(targetVehicleIndex, 1, targetVehicle)
          }
          const targetVehicleIndex = this.vehiclesData.findIndex((v) => v.id === response.id)
          const targetVehicle = this.vehiclesData[targetVehicleIndex]
          targetVehicle.user_id = response.user_id
          targetVehicle.user_name = response.user.name
          this.vehiclesData.splice(targetVehicleIndex, 1, targetVehicle)
          this.myVehicle = targetVehicle
          this.confirmLoading = false
          this.visibleRide = false
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': 乗車に失敗しました。',
            })
            this.confirmLoading = false
            this.visibleRide = false
          })
      }
    },

    handleOkGettingOff() {
      const _this = this
      this.confirmLoading = true
      const result = Vue.prototype.$api.send('put', 'user/vehicle/getting_off')
      result.then(response => {
        _this.$notification['success']({
          message: response.name + ' を降車しました。',
        })
        const targetVehicleIndex = this.vehiclesData.findIndex((v) => v.id === this.myVehicle.id)
        const targetVehicle = this.vehiclesData[targetVehicleIndex]
        targetVehicle.user_id = null
        targetVehicle.user_name = ''
        this.vehiclesData.splice(targetVehicleIndex, 1, targetVehicle)
        this.myVehicle = null
        this.confirmLoading = false
        this.visibleGettingOff = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 降車に失敗しました。',
          })
          this.confirmLoading = false
          this.visibleGettingOff = false
        })
    },
    handleOkGettingOffUser() {
      const _this = this
      this.confirmLoading = true
      const result = Vue.prototype.$api.send('put', 'vehicles/' + this.targetVehicleId + '/getting_off')
      result.then(response => {
        _this.$notification['success']({
          message: this.targetUserName + ' を降車させました。',
        })
        const targetVehicleIndex = this.vehiclesData.findIndex((v) => v.id === response.id)
        const targetVehicle = this.vehiclesData[targetVehicleIndex]
        const rideUserId = targetVehicle.user_id
        targetVehicle.user_id = null
        targetVehicle.user_name = ''
        this.vehiclesData.splice(targetVehicleIndex, 1, targetVehicle)
        if (this.myVehicle && this.myId === rideUserId) {
          this.myVehicle = null
        }
        this.confirmLoading = false
        this.visibleGettingOffUser = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ユーザーの降車に失敗しました。',
          })
          this.confirmLoading = false
          this.visibleGettingOffUser = false
        })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";

.list-name {
  font-size: 1.5rem;
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

</style>
